.timeline-outer-wrapper {
  max-width: 50rem;
  margin: 3rem auto;
}
.timeline-inner-wrapper {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 .75rem;
  color: var(--secondary-color);
}
.timeline-inner-wrapper:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: var(--primary-color);
}
.timeline-inner-wrapper .timeline-container {
  position: relative;
  margin-bottom: 2.5rem;
}
.timeline-inner-wrapper .timeline-container .timeline-icon {
  position: absolute;
  left: -88px;
  top: 4px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
  background: var(--active-color);
}
.timeline-inner-wrapper .timeline-container .timeline-icon i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.timeline-inner-wrapper .timeline-container .timeline-icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.timeline-inner-wrapper .timeline-container .timeline-body {
  background: var(--secondary-color);
  border-radius: var(--button-border-radius);
  padding: 20px 20px 15px;
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
}

.timeline-inner-wrapper .timeline-container .timeline-body .timeline-title {
  margin: 0;
}
.timeline-inner-wrapper .timeline-container .timeline-body .timeline-title .badge {
  background: var(--primary-color);
  color: var(--secondary-color);
  padding: .25rem .5rem;
  border-radius: var(--button-border-radius);
  font-size: 1.25rem;
  font-weight: bold;
}
.timeline-inner-wrapper .timeline-container .timeline-body .timeline-subtitle {
  font-weight: 400;
  font-style: italic;
  color: var(--primary-color);
  margin-top: 1rem;
  font-size: 0.75rem;
}
.timeline-inner-wrapper .timeline-container.primary .badge, .timeline-inner-wrapper .timeline-container .timeline-icon {
  background: var(--active-color);
}

@media (max-width:1050px) {
  .timeline-inner-wrapper {
    position: relative;
    padding-left: 2rem;
    
  }
  .timeline-inner-wrapper .timeline-container .timeline-body .timeline-title .badge {
    color: var(--primary-color);
    background-color: transparent;
    padding: 0;
  }
  .timeline-inner-wrapper .timeline-container .timeline-icon {
    left: -3.5rem;
  }
  .timeline-outer-wrapper {
    margin: 1rem;
  }
}

@media (min-width:1050px){
  .timeline-inner-wrapper .timeline-container .timeline-body:before {
    content: '';
    background: inherit;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    position: absolute;
    left: -0.625rem;
    transform: rotate(45deg);
    border-radius: 0 0 0 2px;
  }
}