.preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: var(--secondary-color);
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 1;
  }
  .preloader.loaded {
    opacity: 0;
    visibility: hidden;
  }
  .preloader .spinner_wrap {
    position: absolute;
    top: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 33333;
  }
  
  .preloader .spinner {
    width: 150px;
    height: 150px;
    background-image: url(/src/images/XS-Logo-Black.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  [data-theme="dark"] .preloader .spinner {
    background-image: url(/src/images/XS-Logo-White.png);
    
  }

  .slide-out {
    animation: pull-down 0.75s ease-in-out alternate;
  }
  @keyframes pull-down {
    from { transform: translateX(0%); opacity: 1;}
    to { transform: translateY(100%);opacity: 0;}
  }
  
  @-webkit-keyframes sk-rotateplane {
    0% {
      -webkit-transform: perspective(120px);
    }
    50% {
      -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
  }
  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  