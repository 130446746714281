.slider-container {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 1rem;
  border-radius: var(--button-border-radius);
  overflow: hidden;
}

.slider-container.half {
  flex: 0 1 calc(50% - 4rem);
}

.slider-container.one-third {
    flex: 0 1 calc(33.33333% - 4rem);
  }

@media(max-width: 992px) {
    .slider-container {
        flex: 0 1 100% !important;
      }
}

.slider-slide-container {
  position: relative;
  aspect-ratio: 3 / 2;
}

.slider-slide {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: calc(var(--button-border-radius) / 2);
}

.slider-arrow-right {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 1rem;
  font-size: 3rem;
  color: var(--active-color);
  z-index: 1;
  cursor: pointer;
}

.slider-arrow-left {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 1rem;
  font-size: 3rem;
  color: var(--active-color);
  z-index: 1;
  cursor: pointer;
}

.slider-dots-container {
  display: flex;
  justify-content: center;
}

#slider-dots-dot {
  margin: 0.5rem 0.25rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--active-color);
}

@media (max-width: 768px) {
  #slider-dots-dot {
    font-size: 0.75rem;
  }
  .slider-arrow-right,
  .slider-arrow-left {
    font-size: 1.5rem;
  }
}

.slider-caption {
  color: var(--text-bright);
  font-weight: bold;
  border-radius: var(--button-border-radius);
  padding: .25rem .5rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: var(--active-color);
}

.slide-in-left {
  animation: slide-in-left .5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  animation: slide-in-right .5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slider-slide.background {
  position: absolute;
  padding-top: 0;
  right: 1rem;
  left: 1rem;
  top:  1rem;
  z-index: -1;
  width: unset;
  aspect-ratio: 3 / 2;
}