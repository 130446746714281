.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--frosted-bg);
  backdrop-filter: blur(5px);
  z-index: 1001;
}

.modal-container {
  width: 80%;
  max-height: 80%;
  max-width: 40rem;
  padding: 1.5rem;
  margin: 1rem;
  background: var(--menu-container);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: var(--button-border-radius);
  overflow: auto;
  box-shadow: var(--shadow);
}

.modal .close-btn {
  position: fixed;
  top: 2rem;
  right: 2rem;
  font-size: 2rem;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 1050px) {
  .modal .close-btn {
    display: none;
  }
}

.hide {
  display: none;
}

.modal p {
  margin: 0.5rem 0;
}

.modal a {
  display: flex;
  gap: .5rem;
}

#modal .modal-container .userform-container {
  margin: 0;
  padding: 0;
}

a#modal-link {
  text-transform: uppercase;
}