.to-top-button {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  z-index: 2;
}

.to-top-button svg {
  font-size: 2rem;
  transition: var(--transition);
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: 50%;
}

.to-top-button-items-container {
  position: absolute;
  bottom: -.375rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  background: var(--menu-container);
  padding: 0.75rem;
  border-radius: var(--button-border-radius);
  z-index: 1;
}

.btn-to-top {
  background-color: var(--active-color);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border: none !important;
}

.btn-to-top i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.btn-to-top:hover i {
  color: var(--primary-color);
}

.to-top-button-items {
  border-radius: 50%;
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--active-color);
  
}

.to-top-button-items > a {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.to-top-button-items:hover,
.to-top-button-items:active {
  background-color: var(--secondary-color);
}

.to-top-button-items i {
  color: var(--primary-color);
  margin: 0;
}

.hidden {
  width: 0;
  padding: 0;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  z-index: 0;
  background-color: transparent;
  color: transparent;
  border: none;
  bottom: -.125rem;
  visibility: hidden;
  transition: var(--transition);
}
.not-hidden {
  padding: 0.25rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  left: 50%;
  transform: translateX(-50%);
}
.initial {
  bottom: 1rem;
  transition: var(--transition);
  z-index: 1;
}
.to-top-button-items:nth-child(2) {
  margin-right: 3rem;
}
.greyed-out {
  opacity: .25;
}