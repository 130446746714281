.userform-container {
  margin: 1rem;
  border-radius: var(--button-border-radius);
  width: 100%;
}

.userform-container form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
}

.userform-container form label {
  font-weight: bold;
}

.userform-container form > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  flex: 1 0 calc(50% - 1rem);
  align-items: flex-start;
}

#userform-save-btn, #choose-theme {
  flex-basis: 100%;
}

@media (max-width: 768px) {
  .userform-container form > div {
    flex: 0 1 100%;
  }
}

.userform-container input {
  padding: 1rem;
  width: calc(100% - 2rem);

}

input.input-secondary-color, input.input-primary-color, input.input-background-color {
  width: unset;
}

.user-name {
  flex: flex;
  gap: 0.25rem;
}
.btn-dark:hover p.saved, .btn-dark:hover p.saved span {
  color: var(--primary-color);
}

p.saved {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0;
  color: var(--secondary-color);
}

p.saved span{
  font-weight: bold;
}

.userform-label {
  color: var(--primary-color);
}

.contact-page .modal .userform-container {
  margin: 0;
  padding: 0;
}

.userform-message {
  min-height: 6rem;
}

.userform-content-full {
  flex-basis: 100% !important;
}

#canvas {
  display: none;
}

.userform-container.success i {
  font-size: 3rem;
  color: var(--success-color)
}

.privacy-notice a {
  padding: 0;
}