.rounded-navbar-toggle-btn {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  transition: all 0.125s ease-in;
  color: var(--text-bright);
  background-color: rgba(var(--highlight-color));
  border: 1px solid var(--text-dark);
}
.rounded-navbar-toggle-btn.active {
  transform: translate(-50%, -3rem);
  color: rgba(var(--highlight-color));
  background-color: var(--text-dark);
  border: 2px solid var(--text-dark);

}
.rounded-navbar-item {
  position: absolute;
  bottom: 6.25rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  color: var(--text-dark);
  background-color: rgba(var(--highlight-color), 1);
  z-index: 2;
  border: .5px solid var(--text-dark);
}
.rounded-navbar-item.visible {
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-name: fadeIn;
  animation-duration: 2s;
}

.rounded-navbar-item.visible:nth-child(2){
    transform:translate(-50%, -3rem)
 }
 
 .rounded-navbar-item.visible:nth-child(3) {
    transform:translate(calc(-50% + 2rem), -2rem)
 }
 
 .rounded-navbar-item.visible:nth-child(4){
    transform:translate(calc(-50% + 3rem), 0)
 }
 
 .rounded-navbar-item.visible:nth-child(5) {
    transform:translate(calc(-50% + 2rem), +2rem)
 }
 
 .rounded-navbar-item.visible:nth-child(6) {
    transform:translate(-50%, 3rem)
 }
 
 .rounded-navbar-item.visible:nth-child(7) {
    transform:translate(calc(-50% - 2rem), +2rem)
 }
 
 .rounded-navbar-item.visible:nth-child(8) {
    transform:translate(calc(-50% - 3rem), 0)
 }
 
 .rounded-navbar-item.visible:nth-child(9) {
    transform:translate(calc(-50% - 2rem), -2rem)
 }

button.rounded-navbar-toggle-btn.active:after {
  content: "";
  width: 140px;
  height: 140px;
  background-color: rgba(var(--highlight-color), 0.5);
  position: absolute;
  z-index: 0;
  border-radius: 50%;
  border: .5px solid var(--text-dark);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 3s;
  animation-name: fadeIn;
  animation-duration: .5s;
  position: absolute;
  z-index: -1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}