.usercard-container {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.usercard-body {
  background-color: var(--secondary-color);
  border-radius: var(--button-border-radius);
  padding: 1.5rem;
  margin: 1rem 1rem 1rem 1rem;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 2rem;
  transition: all ease .5s;
}

.usercard-body.visible {
  z-index: 1;
  margin: -5rem 1rem 1rem 1rem;
  height: 100%;
  box-shadow: rgba(var(--image-shadow), 0.75) 0px -50px 50px 0px
}

.usercard-body p {
  transform: translateY(400px);
}

.usercard-body.visible p {
  transform: translateY(0px);
}

.usercard-image {
  z-index: 1;
}

.usercard-image img {
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: 100%;
  max-width: 250px;
  border: 2px solid var(--primary-color);
}