.cta {
    margin: 1rem;
}

.cta-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
   
    padding: 1.5rem;
    background-color: var(--secondary-color);
    border-radius: var(--button-border-radius);
    max-width: 800px;
    
    margin: 0 auto ;

}
.cta-container p {
    margin-top: 0;
}