.termin-iframe {
    width: 100%; 
    height: 2000px;
    background-color: #f0f0f0;
    border-radius: var(--button-border-radius);
    margin: 1rem;
    border: none;
}

@media (max-width: 992px) {
    .termin-iframe {
        margin: 0;
        height: 3000px;
    }
}