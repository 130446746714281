.score {
    margin-top: 20px;
    font-size: 24px;
    color: #fff;
    z-index: 1;
  }
  
  .start-screen,
  .game-over-screen {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
  }
  
  .start-screen button,
  .game-over-screen button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #e77100;
    border: none;
    color: white;
    cursor: pointer;
  }

  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #282c34;
    position: relative;
  }
  
  canvas {
    width: 100%;
    max-width: 800px;
    background-color: #000;
  }