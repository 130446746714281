.toast {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 1000;
    cursor: pointer;
    font-weight: bold;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toast-link i {
    margin-right: .5rem;
  }
  