.fullscreen-section-container {
  width: 100%;
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.fullscreen-section {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
}

.fullscreen-section-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  margin: 1rem;
  padding: 1rem;
}

.fullscreen-background {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center;
  filter: grayscale(1);
}

.fullscreen-navigation-container {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.fullscreen-navigation-items > i {
  color: var(--active-color);
  font-size: 1rem;
  z-index: 5;
}

.section-arrow-down {
  position: absolute;
  bottom: 3.5rem;
  transform: translate(-50%, 0);
  left: 50%;
  font-size: 3rem;
  color: var(--active-color);
  z-index: 1;
  cursor: pointer;
}

.fullscreen-section-content p,
h1.fullscreen-section-title {
  color: var(--text-dark);
}

.fullscreen-section-content p {
  max-width: 600px;
}

@media (max-width: 768px) {
  .fullscreen-section-content p {
    max-width: 80%;
  }
}





