@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/public/fonts/OpenSans-Light.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/public/fonts/OpenSans-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('/public/fonts/OpenSans-Italic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/public/fonts/OpenSans-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/public/fonts/OpenSans-Bold.ttf') format('truetype');
  }
  
  * {
    font-family: 'Open Sans', sans-serif;
  }
  

  