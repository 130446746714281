.topbar {
  background: var(--topnav-container);
}

.topbar-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 6rem;
  gap: 1rem;
}

.topbar-container div.user-icon {
  margin: 0;
  padding: 0.25rem;
  font-size: .5rem;
  width: .75rem;
  height: .75rem;
  font-weight: bold;
  background-color: #1e1e1e;
  border-radius: 50%;
  text-align: center;
  border: 1px solid var(--primary-color);
  color: var(--text-dark);
}
.topbar-container p {
  color: var(--text-dark);
  margin: 0;
}

.topbar .user-settings > i {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.topbar button.user-settings {
  background-color: transparent;
  color: var(--icon-color-navbar);
  display: flex;
  padding: 0;
}

@media screen and (max-width: 1500px) {
  .topbar-container {
    padding: .5rem 2rem;
  }
}

@media (max-width: 992px) {
  .topbar-user-greeting, .topbar-user-appointment > span {
    display: none !important;
  }
  .user-icon {
    margin-right: auto !important;
  }
}
