.list-wrapper {
    margin: 1rem;
    width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.list-wrapper h3 {
    margin-bottom: 1rem;
}

.list-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    overflow: clip;
}

.list-container p {
    text-align: center;
}

.list-container p i {
    margin-right: .5rem;
}

.filter-buttons-container {
    display: flex;
    align-items: flex-start;
    gap: .5rem;
    max-width: 100%;
    overflow-x: auto;
}

.filter-buttons-container::-webkit-scrollbar {
    height: 0;
}

.filter-buttons-container button {
    text-transform: uppercase;
    white-space: nowrap;
}

.list-search-input {
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
    flex: 1;
}

.list-items {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1rem 0;
    padding: 0;
    width: 100%;

}

.list-items li {
    list-style-type: none;
    font-weight: bold;
    border: 2px solid var(--secondary-color);
    border-radius: var(--button-border-radius);
    padding: 1rem;
    display: flex;
    gap: .75rem;
    justify-content: space-between;
    align-items: center;
    color: var(--body-color);
    cursor: pointer;
}

.list-item-details {
    display: flex;
    align-items: center;
    gap: .75rem;
}

.list-item-price {
    font-weight: bold;
}

.list-item-category {
    margin-left: auto;
    padding: .5rem;
    border-radius: 50%;
    background: var(--active-color);
    color: var(--icon-color);
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}