.box {
  padding: 1.5rem;
  margin: 1rem;
  border-radius: var(--button-border-radius);
}

.box.no-padding {
  padding: 0;
}



.box-info__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.box-info__container button {
  margin-top: 1rem;
}


.box-tilt.box-half {
  transform: rotate3d(60, 45, 22, -45deg) scale(0.9);
  transition: all ease-in .25s;
  box-shadow: var(--box-shadow);
  
}

.box-tilt.box-half:hover {
  transform: rotate3d(120, -20, 45, 0deg);
  box-shadow: none;
}

.box-img__container > img {
  width: 100%;
  margin-bottom: 1.5rem;
}

.box.image-mask .box-img__container img {
  mask-image: url(/src/images/imagemask.png);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}

.box-img-rounded {
  border-radius: calc(var(--button-border-radius) / 2);
}

.box-img-no-margin {
  margin-bottom: 0 !important;
}

.box-headline {
  font-size: 1.75rem;
  letter-spacing: .1rem;
}

.headline-bright {
  color: var(--text-bright);
}

.text-bright {
  color: var(--text-bright);
}

.text-no-margin {
  margin: 0 !important;
}

.box-bright {
  background-color: var(--primary-color);
}

.headline-dark {
  color: var(--text-dark);
}

.text-dark {
  color: var(--text-dark);
}

.box-dark {
  background-color: var(--secondary-color);
}

a.box-text {
  padding: 0;
}

.box-info__container p,
.box-info__container a {
  margin: 0.5rem 0;
}


.box-icon-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.box-tilt .box-icon-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  animation: upAndDown;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.box-tilt.box-half:hover .box-icon-container {
  animation: none;
}

.box-icon-container i {
  font-size: 3rem;
}

@keyframes upAndDown {
  0% {
    transform: translate(0px, 0px);
    filter: drop-shadow(0px 0px #0000004a);
  }
  50% {
    transform: translate(20px, -40px);
    filter: drop-shadow(-10px 20px #00000020);
  }
  100% {
    transform: translate(0px, 0px);
    filter: drop-shadow(0px 0px #0000004a);
  }
}
