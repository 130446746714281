.navbar-links_container .active {
  font-weight: bold;
  border: 2px solid var(--primary-color);
  border-radius: var(--button-border-radius);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
  background-color: var(--secondary-color-transparent);
  border-bottom: 2px solid var(--primary-color);
  max-height: var(--margin-top);
  z-index: 5;
  position: relative;
}

.navbar-links {
  display: flex;
  flex: 1;
  margin-right: 1rem;
  align-items: center;
}

.navbar-links_logo {
  margin-right: 1rem;
}

.navbar-links_logo a {
  padding: 0;
}

.navbar-links_logo img {
  width: 6rem;
}

@media (max-width: 1500px) {
  .navbar-links_logo img {
    width: 3rem;
  }
}

.navbar-links_container {
  display: flex;
  flex-direction: row;
}

.navbar-menu_container-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
}

.navbar-menu_container-links #main-logo {
  width: 3rem;
  margin-bottom: 2rem;
}

.navbar-links_container p,
.navbar-links_container a {
  text-transform: uppercase;
  cursor: pointer;
  color: var(--text-bright);
  text-decoration: none;
  display: block;
  white-space: nowrap;
}

.navbar-links_container a:hover {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: var(--button-border-radius);
}

.navbar-menu_container p,
.navbar-menu_container a {
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
  color: var(--body-color);
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.25rem 0;
}

.navbar-links_container p {
  margin: 0 0.125rem;
}

.navbar-links_container a {
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
}

.navbar-menu_container .navbar-menu_container-links p a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar-menu_container .navbar-menu_container-links p a i {
  margin-right: 0.5rem;
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.navbar-menu_container .navbar-menu_container-links p a span {
  font-size: 1.25rem;
}

.navbar-menu_container .navbar-menu_container-links p a.active i {
  background-color: var(--active-color);
}

.navbar-links_container a i {
  display: none;
}

.navbar-sign {
  display: flex;
  gap: 0.5rem;
}

.navbar-sign a {
  text-transform: uppercase;
  color: var(--text-dark);
  transition: var(--transition);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding: 0;
}

.navbar-sign a:hover span {
  color: var(--primary-color);
}
.navbar-sign a span {
  color: var(--background-color);
}
.navbar-menu_container-links-sign a {
  text-transform: uppercase;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.navbar-sign a i ,
.navbar-menu_container-links-sign a i {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  color: var(--primary-color);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
}

.navbar-sign a:hover i {
  color: var(--background-color);
  background-color: var(--primary-color);
  border: 1px solid var(--background-color);
}

.navbar-sign a span,
.navbar-menu_container-links-sign a span {
  font-size: 0.75rem;
}

.navbar-sign a span {
  white-space: nowrap;
}

.navbar-sign .button-contrast a,
.navbar-menu_container-links-sign .button-contrast a {
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
}

.navbar-menu_container-links-sign button {
  margin: 0;
}

.navbar-sign button:hover,
.navbar-menu_container-links-sign button:hover {
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
}

.navbar-sign .button-contrast button:hover,
.navbar-menu_container-links-sign .button-contrast button:hover {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
}

.navbar-menu__icon-container > i {
  font-size: 1.25rem !important;
}

.nav-menu svg,
svg.menu-icon {
  cursor: pointer;
}

.navbar-menu_container-overlay {
  position: fixed;
  inset: 0;
  background-color: var(--frosted-bg);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  background: var(--menu-container);
  padding: 1.5rem;
  border-radius: var(--button-border-radius);
  z-index: 10;
  overflow: hidden;
  transition: all ease-in 0.25s;
  box-shadow: var(--shadow);
  width: 80%;
  max-width: 40rem;
  margin: 1rem;
  position: relative;
}
.navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1500px) {
  .navbar-links_container {
    display: none;
  }

  .navbar-menu {
    display: flex;
  }
  .navbar-menu > div > i {
    font-size: 2rem;
    color: var(--primary-color);
    cursor: pointer;
  }
  .navbar {
    padding: 1rem 2rem;
    transition: padding 0.25s ease-in;
  }

  .navbar-sign {
    display: none;
  }

  .navbar-menu_container-links-sign {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .navbar-menu_container-links-sign a {
    margin: 0;
  }
}

.menu-icon {
  color: var(--text-bright);
}

.navbar-menu_container-links a.active {
  color: var(--active-color);
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.scrolled.navbar {
  padding: 0.5rem 6rem;
}
.scrolled.navbar .navbar-links_logo img {
  width: 3rem;
}
@media (max-width: 1500px) {
  .scrolled.navbar {
    padding: 0.5rem 2rem;
  }
  .scrolled.navbar .navbar-links_logo img {
    width: 2rem;
  }
}

.navbar-menu-container-side-toggle {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  color: var(--text-bright);
  cursor: pointer;
}

.navbar-menu-container-side-toggle > i {
  font-size: 1.5rem;
}

.fade-out {
  transform: translateX(-100%);
  opacity: 0;
}

.fade-in {
  transform: translateX(0%);
  opacity: 1;
  transition: transform ease-in 0.25s, opacity ease-in 0.25s;
}

.navbar-current-page {
  color: var(--text-bright);
  font-weight: bold;
  margin-left: auto;
  text-transform: uppercase;
}
