.fullscreen-overlay {
    width: 100%;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    background-color: var(--secondary-color);
    display: block;
    
}
.fullscreen-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 1rem 0
    ;
}

.fullscreen-overlay__logo {
    max-width: 200px;
}
@media (max-width: 768px){
    .fullscreen-overlay__logo {
        width: 100px;
    }
}

.fullscreen-unlock {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.fullscreen-password {
    border: none;
    padding: 0.75rem 1.5rem;
    text-align: center;
}

.fullscreen-overlay h2,
.fullscreen-overlay p {
    text-align: center;
}

.fullscreen-password-message {
    color: red;
    font-weight: bold;
}
.accordion-container {
    width: 100%;
}