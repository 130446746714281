.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    grid-gap: .5rem;
    width: 100%;
    padding: 1rem;
}

@media screen and (max-width: 992px) {
    .gallery {
         grid-template-columns: 1fr 1fr;
     }
 }

 @media screen and (max-width: 768px) {
    .gallery {
         grid-template-columns: 1fr 1fr 1fr;
     }
     .gallery-image {
        aspect-ratio: 1 / 1;
     }
 }

 .gallery-image-container {
    overflow: hidden;
    border-radius: var(--button-border-radius);
}

.gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all ease-in .25s;
    filter: grayscale(1);
}

.gallery-image:hover {
    transform: scale(1.1);
    z-index: 1;
    cursor: pointer;
}

.gallery-fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 2rem;
    background-color: var(--background-color-transparent);
    backdrop-filter: blur(5px);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-image-fullscreen {
    width: 100%;
    max-width: 800px;
    cursor: pointer;
    border-radius: var(--button-border-radius);
}

.gallery-fullscreen-container button {
    position: absolute;
    top: 1rem;
    right: 2rem;
}