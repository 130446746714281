.highlights {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.highlight {
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid var(--body-color);
    border-radius: var(--button-border-radius);
    overflow: hidden;
    position: relative;
}

.highlight.highlight-bookable {
    border-color: var(--active-color);
    cursor: pointer;
}

.highlight-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    height: 100%;
}

.highlight-container > div {
    height: 2rem;
    width: 2rem;
    padding: .75rem;
    border-radius: 50%;
    background: var(--active-color);
}

.highlight-container svg {
    font-size: 2rem;
    color: var(--icon-color);
}

.highlight-container h4 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--body-color);
    margin-bottom: 0;
    margin-top: 1rem;
}
.highlight-container p {
    margin-top: 0;
    text-align: center;
    color: var(--body-color);
}

p.hightlight-flag {
    position: absolute;
    top: 30px;
    right: -35px;
    background: var(--active-color);
    color: var(--body-color);
    margin: 0;
    padding: 0 2rem;
    font-weight: bold;
    transform: rotate(45deg);
}

p.hightlight-price {
    margin-top: auto;
    font-weight: bold;
    font-size: 1.5rem;
}

